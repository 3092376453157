import api from '@cuidardigital/commons/services/api'
import { endpointsCreator } from '@cuidardigital/commons/services/endpoints'

interface ILogin {
	[key: string]: any
}

const login = async (values: ILogin) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.USER.LOGIN, {
			login: values.login.replace(/[^\d]/g, ''),
			password: values.password
		})

		return response
	} catch (err) {
		return err.response
	}
}

const reset = async (cpf: string) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.USER.RESET_PASSWORD, {
			username: cpf.replace(/[^\d]/g, '')
		})

		return response
	} catch (err) {
		return err.response
	}
}

export { login, reset }
